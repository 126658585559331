import { Avatar, IconButton } from '@mui/material'
import { Tooltip } from '@mui/material'
import { Typography } from '@mui/material'
import { Box } from '@mui/material'
import { IconBolt, IconBubbleText } from '@tabler/icons-react'

import AiFeature from 'src/components/AiFeature/AiFeature'
import ContactAvatar from 'src/components/ContactAvatar/ContactAvatar'
import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import OrganizationSocialLinks from 'src/components/Organizations/OrganizationSocialLinks/OrganizationSocialLinks'
import Row from 'src/components/Row/Row'
import { actionButtonStyle } from 'src/components/Sidebar/styles'
import ThreadCreateButton from 'src/components/Threads/ThreadCreateButton/ThreadCreateButton'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'
import { searchMetadataBuilders } from 'src/lib/Objects/build'
import { buildGqlObject } from 'src/lib/Objects/build'

import { SidebarMode } from './SidebarContext'
import useSidebar from './useSidebar'

const SidebarHeader = () => {
  const { object, setSidebarObject, closeSidebar } = useSidebar()
  const metadata = searchMetadataBuilders[object.objectType](object)
  const gqlObject = buildGqlObject(object)
  logger.dev(`gqlObject: ${gqlObject}`)
  return (
    metadata && (
      <Row className="header-row">
        <Row
          gap={2}
          sx={{ pt: 2 }}
        >
          {object.objectType === NativeObjectTypes.Organization ? (
            <DomainAvatar domain={object.objectId} />
          ) : object.objectType === NativeObjectTypes.Person ? (
            <ContactAvatar email={object.objectId} />
          ) : (
            <Avatar
              className="main-avatar"
              src={metadata.photoUrl}
            />
          )}
          <Box>
            <Row className="object-title-row">
              <Row gap={1}>
                <Typography variant="h2">{metadata.label}</Typography>
                {object.objectType === NativeObjectTypes.Organization && (
                  <OrganizationSocialLinks
                    organization={gqlObject}
                    iconSize={10}
                  />
                )}
              </Row>

              <Row className="action-buttons">
                <AiFeature
                  workspaceId={object.workspaceId}
                  featureKey={object.objectType}
                  objectId={object.objectId}
                  variant="outlined"
                  size={16}
                  lastUpdated={new Date(object?.updatedAt)}
                  sx={{ p: '6px' }}
                />
                <ThreadCreateButton
                  workspaceId={object.workspaceId}
                  sourceObject={{
                    objectType: object.objectType,
                    objectId: object.objectId,
                    properties: object,
                  }}
                  otherObjects={[]}
                  onThreadCreated={() => {
                    closeSidebar()
                  }}
                  variant="icon"
                  disableElevation={false}
                />
                <Tooltip title={`Add context to ${metadata.label}`}>
                  <IconButton
                    onClick={() => {
                      setSidebarObject({
                        mode: SidebarMode.CREATE,
                        object: {
                          objectType: NativeObjectTypes.UserContext,
                        },
                      })
                    }}
                    sx={actionButtonStyle}
                    className={'disable-elevation'}
                  >
                    <IconBubbleText size={14} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={`Create an action to do for ${metadata.label}`}>
                  <IconButton
                    onClick={() => {
                      setSidebarObject({
                        mode: SidebarMode.CREATE,
                        object: {
                          objectType: NativeObjectTypes.Action,
                        },
                      })
                    }}
                    sx={actionButtonStyle}
                    className={'disable-elevation'}
                  >
                    <IconBolt size={14} />
                  </IconButton>
                </Tooltip>
              </Row>
            </Row>
            <Typography className="description">
              {metadata.description}
            </Typography>
          </Box>
        </Row>
      </Row>
    )
  )
}

export default SidebarHeader
