import { Tab } from '@mui/material'
import { Tabs } from '@mui/material'

import { SidebarMode } from './SidebarContext'
import useSidebar from './useSidebar'

const SidebarTabs = () => {
  const { mode, setMode } = useSidebar()
  return (
    <Tabs
      value={mode}
      onChange={(_, value) => setMode(value)}
      className="sidebar-tabs"
    >
      <Tab
        label="About"
        value={SidebarMode.VIEW}
      />
      <Tab
        label="Draft"
        value={SidebarMode.DRAFT}
      />
      <Tab
        label="Chat"
        value={SidebarMode.CHAT}
      />
    </Tabs>
  )
}

export default SidebarTabs
