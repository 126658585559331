import { createContext } from 'react'

import type {
  DayObject,
  DayProperty,
  ObjectPropertyDefinition,
} from 'types/graphql'

import { NativeObjectTypes } from 'src/lib/objects'

import type { NavigationUserAction } from './navigation'

export enum SidebarMode {
  VIEW = 'view',
  EDIT = 'edit',
  CREATE = 'create',
  DRAFT = 'draft',
  CHAT = 'chat',
  PROPERTY = 'property',
  CONSOLE = 'console',
}

export const SidebarModesEnabled = {
  [NativeObjectTypes.Person]: {
    view: true,
    edit: true,
    create: true,
    console: true,
  },
}

export type SidebarState = {
  mode: SidebarMode
  object: DayObject
  propertyToExamine?: string
}

export enum SidebarActions {
  SET_MODE = 'SET_MODE',
  SET_OBJECT = 'SET_OBJECT',
  NAVIGATE_BACK = 'NAVIGATE_BACK',
  NAVIGATE_FORWARD = 'NAVIGATE_FORWARD',
  RESET = 'RESET',
  UPDATE_OBJECT = 'UPDATE_OBJECT',
  UPDATE_PROPERTY = 'UPDATE_PROPERTY',
  EXAMINE_PROPERTY = 'EXAMINE_PROPERTY',
}

export type SidebarAction = {
  type: SidebarActions
  payload: any
}

export type SidebarContextValue = {
  workspaceId: string | null
  forward: NavigationUserAction
  back: NavigationUserAction
  object: DayObject
  mode: SidebarMode
  setSidebarObject: ({
    object,
    mode,
  }: {
    object: Partial<DayObject>
    mode: SidebarMode
  }) => void
  setMode: (mode: SidebarMode) => void
  closeSidebar: () => void
  updateProperty: (property: DayProperty) => void
  examineProperty: (propertyId: string) => void
  propertyToExamine: string | null
  propertyDefinitions: ObjectPropertyDefinition[]
}

const SidebarContext = createContext<SidebarContextValue | undefined>(undefined)

export default SidebarContext
