import { Box, Chip, IconButton, Tooltip } from '@mui/material'
import {
  IconBrandLinkedin,
  IconCancel,
  IconCheck,
  IconCircleFilled,
  IconClock,
  IconLoader,
  IconPhone,
  IconThumbUp,
  IconX,
} from '@tabler/icons-react'
import type {
  DayObject,
  ObjectPropertyDefinition,
  ObjectPropertyDefinitionOption,
} from 'types/graphql'

import { logger } from 'src/lib/logger'
import { statusMetadata as MeetingRecordingStatusMetadata } from 'src/lib/meetingRecordingFormatting'
import { NativeObjectTypes, type NativeObjectType } from 'src/lib/objects'
import { buildGqlObject } from 'src/lib/Objects/build'

import MultiObjectChip from '../Chips/MultiObjectChip/MultiObjectChip'
import ObjectChip from '../Chips/ObjectChip/ObjectChip'
import Row from '../Row/Row'

export type PropertyRendererProps = {
  propertyDefinition: ObjectPropertyDefinition
  objectId: string
  objectType: NativeObjectType
  workspaceId: string
  value: any
  object: Partial<DayObject>
}

const rowSx = {
  width: '100%',
  height: '100%',
  gap: '2px',
}

export const ObjectChipRenderer = ({
  workspaceId,
  objectType,
  objectId,
  object,
}: PropertyRendererProps) => {
  if (!objectId || !objectType || !workspaceId) {
    logger.warn('No data for ObjectChipRenderer to render', {
      objectType,
      objectId,
      workspaceId,
    })
    return null
  }

  const gqlObject = buildGqlObject(object as DayObject)
  const crmObject = {
    workspaceId,
    objectType,
    objectId,
    properties: gqlObject,
  }

  return (
    <Row sx={rowSx}>
      <ObjectChip
        workspaceId={workspaceId}
        crmObject={crmObject}
      />
    </Row>
  )
}

export const MultiObjectRenderer = ({
  workspaceId,
  value,
}: PropertyRendererProps) => {
  if (!value?.value) {
    logger.warn('No value for MultiObjectRenderer to render')
    return null
  }

  return (
    <Row sx={rowSx}>
      <MultiObjectChip
        workspaceId={workspaceId}
        crmObjects={value.value}
      />
    </Row>
  )
}

const stringArrayChipSx = {
  p: 0,
  overflow: 'hidden',
  flexShrink: 0,
  backgroundColor: (theme) => theme.palette.background.default,
  border: (theme) => `1px solid ${theme.palette.divider}`,
  '& .MuiChip-label': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    fontSize: '11px',
  },
}

export const StringArrayRenderer = ({ value }: PropertyRendererProps) => {
  if (!value?.value) return null

  return (
    <Row sx={rowSx}>
      {value.value.map((item) => (
        <Chip
          key={item}
          label={item}
          size="small"
          variant="filled"
          sx={stringArrayChipSx}
        />
      ))}
    </Row>
  )
}

export const LinkedInUrlRenderer = ({ value }) => {
  if (!value?.value) return null

  const iconSize = 20

  const containerSx = {
    p: 0,
    overflow: 'visible',
    '& .MuiIconButton-root': {
      p: 0,
      height: `${iconSize - 7}px`,
      width: `${iconSize - 5}px`,
      borderRadius: '3px',
      color: '#2867B2',
      transition: '0.2s all ease-in-out',
      '&:hover': {
        backgroundColor: '#2867B2',
        color: '#FFFFFF',
        boxShadow: (theme) => theme.shadows[3],
        border: `2px solid #2867B2`,
      },
      '& .tabler-icon': {
        ml: `0px`,
        mt: `-2px`,
        flexShrink: 0,
      },
    },
  }
  return (
    <Box sx={containerSx}>
      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          window.open(value.value, '_blank')
        }}
      >
        <IconBrandLinkedin size={iconSize} />
      </IconButton>
    </Box>
  )
}

interface EnumPropertyMetadataOption {
  color: string
  label: string
  icon: React.ElementType
}

interface EnumPropertyMetadata {
  tooltipTitle: (row: any) => string
  options: Record<string, EnumPropertyMetadataOption>
}

export const enumPropertyMetadata: Record<
  NativeObjectType,
  Record<string, EnumPropertyMetadata>
> = {
  [NativeObjectTypes.MeetingRecording]: {
    statusLabel: {
      tooltipTitle: (row) => {
        const statusSummary =
          MeetingRecordingStatusMetadata[
            row?.object?.properties?.standard?.statusLabel?.value
          ]?.explanation || ''
        const messageKey =
          row?.object?.properties?.standard?.statusReason?.value
        return `${statusSummary} ${row?.object?.properties?.standard?.statusMessage?.value || ''} ${messageKey ? 'CODE: ' + messageKey : ''}`
      },
      options: {
        ['JOINING_CALL']: {
          color: 'success',
          label: 'Joining Call',
          icon: IconPhone,
        },
        ['READY']: {
          color: 'success',
          label: 'Ready',
          icon: IconCheck,
        },
        ['IN_WAITING_ROOM']: {
          color: 'warning',
          label: 'In Waiting Room',
          icon: IconClock,
        },
        ['IN_CALL_NOT_RECORDING']: {
          color: 'warning',
          label: 'In Call Not Recording',
          icon: IconClock,
        },
        ['RECORDING_PERMISSION_ALLOWED']: {
          color: 'success',
          label: 'Recording Allowed',
          icon: IconThumbUp,
        },
        ['RECORDING_PERMISSION_DENIED']: {
          color: 'error',
          label: 'Recording Denied',
          icon: IconX,
        },
        ['IN_CALL_RECORDING']: {
          color: 'error',
          label: 'Recording',
          icon: IconCircleFilled,
        },
        ['PREPARING_RECORDING']: {
          color: 'info',
          label: 'Preparing Recording',
          icon: IconLoader,
        },
        ['CALL_ENDED_WITHOUT_RECORDING']: {
          color: 'info',
          label: 'No Recording',
          icon: IconCancel,
        },
        ['RECORDING_ERRORED']: {
          color: 'error',
          label: 'Errored',
          icon: IconX,
        },
      },
    },
    statusReason: {
      tooltipTitle: (row) => {
        return (
          row?.object?.properties?.standardProperties?.statusMessage?.value ||
          ''
        )
      },
      options: {
        ['CALL_ENDED_BY_PLATFORM_WAITING_ROOM_TIMEOUT']: {
          color: 'warning',
          label: 'Waiting Room Timeout',
          icon: IconClock,
        },
        ['ZOOM_SDK_APP_NOT_PUBLISHED']: {
          color: 'error',
          label: 'Zoom App Not Published',
          icon: IconX,
        },
        ['ZOOM_LOCAL_RECORDING_REQUEST_DENIED_BY_HOST']: {
          color: 'error',
          label: 'Recording Denied by Host',
          icon: IconX,
        },
        ['TIMEOUT_EXCEEDED_EVERYONE_LEFT']: {
          color: 'info',
          label: 'Everyone Left Call',
          icon: IconCancel,
        },
        ['ZOOM_LOCAL_RECORDING_GRANT_NOT_SUPPORTED']: {
          color: 'error',
          label: 'Recording Not Supported',
          icon: IconX,
        },
        ['TIMEOUT_EXCEEDED_RECORDING_PERMISSION_DENIED']: {
          color: 'error',
          label: 'Recording Permission Timeout',
          icon: IconClock,
        },
        ['TIMEOUT_EXCEEDED_IN_CALL_NOT_RECORDING']: {
          color: 'warning',
          label: 'Recording Start Timeout',
          icon: IconClock,
        },
        ['TIMEOUT_EXCEEDED_WAITING_ROOM']: {
          color: 'warning',
          label: 'Waiting Room Timeout',
          icon: IconClock,
        },
        ['ZOOM_LOCAL_RECORDING_DISABLED']: {
          color: 'error',
          label: 'Recording Disabled',
          icon: IconX,
        },
        ['ZOOM_LOCAL_RECORDING_REQUEST_DISABLED']: {
          color: 'error',
          label: 'Recording Requests Disabled',
          icon: IconX,
        },
        ['ZOOM_HOST_NOT_PRESENT']: {
          color: 'warning',
          label: 'Host Not Present',
          icon: IconClock,
        },
        ['TIMEOUT_EXCEEDED_ONLY_BOTS_DETECTED_USING_PARTICIPANT_EVENTS']: {
          color: 'info',
          label: 'Only Bots Detected',
          icon: IconCancel,
        },
        ['BOT_KICKED_FROM_WAITING_ROOM']: {
          color: 'error',
          label: 'Kicked from Waiting Room',
          icon: IconX,
        },
        ['BOT_RECEIVED_LEAVE_CALL']: {
          color: 'info',
          label: 'Bot Left Call',
          icon: IconCancel,
        },
        ['MEETING_NOT_FOUND']: {
          color: 'error',
          label: 'Meeting Not Found',
          icon: IconX,
        },
      },
    },
  },
  [NativeObjectTypes.Contact]: {},
  [NativeObjectTypes.Organization]: {},
  [NativeObjectTypes.Opportunity]: {},
  [NativeObjectTypes.Pipeline]: {},
  [NativeObjectTypes.Stage]: {},
  [NativeObjectTypes.Action]: {},
  [NativeObjectTypes.Page]: {},
  [NativeObjectTypes.WebPage]: {},
  [NativeObjectTypes.MeetingRecordingClip]: {},
  [NativeObjectTypes.Event]: {},
  [NativeObjectTypes.Workspace]: {},
  [NativeObjectTypes.GmailThread]: {},
  [NativeObjectTypes.SlackChannel]: {},
  [NativeObjectTypes.View]: {},
  [NativeObjectTypes.UserContext]: {},
  [NativeObjectTypes.PropertyDefinition]: {},
}

export const enumOptionsAsPropDefOptions = (objectType, propertyId) => {
  const metadata = enumPropertyMetadata?.[objectType]?.[
    propertyId
  ] as EnumPropertyMetadata
  if (!metadata) {
    return []
  }
  return Object.entries(metadata?.options).map(([key, value]) => ({
    id: key,
    name: value.label,
    description: '',
    propertyDefinitionId: key,
  })) as ObjectPropertyDefinitionOption[]
}

export const EnumRenderer = ({
  propertyDefinition,
  objectType,
  value,
  object,
}: PropertyRendererProps) => {
  if (!value?.value) return null

  const propertyId = propertyDefinition.id

  const metadata = enumPropertyMetadata?.[objectType]?.[propertyId]

  const chipMetadata = metadata?.options?.[value?.value]
  const tooltipTitle = metadata?.tooltipTitle?.(object)
  const color = chipMetadata?.color
  const label = chipMetadata?.label

  if (!metadata) {
    logger.warn(
      `EnumCellRenderer: No metadata found for propertyId: ${propertyId} and value: ${value}`,
      {
        objectType,
        propertyId,
        value,
      }
    )
    return null
  }

  const chipSx = {
    border: 'none',
    '& .MuiChip-icon': {
      color: (theme) => theme.palette?.[color]?.main,
      //ml: '-4px',
      flexShrink: 0,
      width: '8px',
      height: '8px',
    },
    '& .MuiChip-label': {
      flexShrink: 1,
      pl: '8px',
    },
  }

  return (
    <Row sx={rowSx}>
      <Tooltip
        title={tooltipTitle}
        arrow={true}
      >
        <Chip
          size="small"
          icon={<IconCircleFilled size={8} />}
          label={label}
          clickable={false}
          sx={chipSx}
          variant="outlined"
        />
      </Tooltip>
    </Row>
  )
}

export const StringArrayValueFormatter = (value: string[]) => {
  return (value || []).join(' ').trim()
}
