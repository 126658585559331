import { useState } from 'react'

import { Box, Button, Typography } from '@mui/material'
import { IconButton } from '@mui/material'
import { IconChevronLeft, IconSparkles } from '@tabler/icons-react'
import type {
  DayObject,
  DayProperty,
  ObjectPropertyDefinition,
} from 'types/graphql'

import PropertyDisplay from 'src/components/Properties/PropertyDisplay'
import PropertyEdit from 'src/components/Properties/PropertyEdit'
import Row from 'src/components/Row/Row'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes, type NativeObjectType } from 'src/lib/objects'
import {
  StandardProperties,
  StandardPropertyAsObjectPropertyDefinition,
  StandardPropertyEditPolicy,
  StandardPropertyTablePolicy,
} from 'src/lib/Properties/properties'

import Relationships from './Relationships'
import SidebarHeader from './SidebarHeader'
import SidebarTabs from './SidebarTabs'
import useSidebar from './useSidebar'

const StandardPropertyRenderer = ({
  propertyId,
  property,
}: {
  propertyId: string
  property: DayProperty
}) => {
  const { object, updateProperty, workspaceId, examineProperty } = useSidebar()
  const standardPropDef = StandardProperties?.[object?.objectType]?.[propertyId]

  const propertyDefinition = StandardPropertyAsObjectPropertyDefinition({
    objectType: object?.objectType as NativeObjectType,
    propertyId: propertyId,
    workspaceId: object?.workspaceId,
  })

  if (!propertyDefinition) {
    logger.dev('Property definition missing', {
      objectType: object?.objectType,
      propertyId: property?.propertyId,
    })
    return null
  }

  return (
    object &&
    propertyDefinition &&
    standardPropDef && (
      <Row className="property-row">
        <Box className="property-edit-container">
          <Box className="property-edit-inner">
            {standardPropDef.editPolicy ===
              StandardPropertyEditPolicy.ALLOW && (
              <PropertyEdit
                propertyDefinition={propertyDefinition}
                objectId={object.objectId}
                objectType={object.objectType}
                workspaceId={workspaceId}
                value={property}
                justify="start"
                onUpdate={(updatedProperty) => {
                  updateProperty(updatedProperty)
                }}
                onClose={() => {}}
                showEditButton={true}
              />
            )}

            {standardPropDef.editPolicy ===
              StandardPropertyEditPolicy.CONTEXT_ONLY && (
              <IconButton
                onClick={() => {
                  examineProperty(propertyId)
                }}
              >
                <IconSparkles />
              </IconButton>
            )}
          </Box>
        </Box>
        <Row className="property-row-content">
          <Typography className="property-name">
            {propertyDefinition.name}
          </Typography>
          <Box className="property-value">
            <PropertyDisplay
              propertyDefinition={propertyDefinition}
              objectId={object.objectId}
              objectType={object.objectType as NativeObjectType}
              workspaceId={object.workspaceId}
              value={property}
              justify="flex-start"
            />
          </Box>
        </Row>
      </Row>
    )
  )
}

const SidebarStandardProperties = () => {
  const { object } = useSidebar()

  const [expanded, setExpanded] = useState(false)

  const defaultShownProperties = Object.entries(
    StandardProperties?.[object.objectType] || {}
  )
    .filter(
      ([_, property]) =>
        property.tablePolicy === StandardPropertyTablePolicy.DEFAULT
    )
    .map(([key]) => key)

  const secondaryShownProperties = Object.entries(
    StandardProperties?.[object.objectType] || {}
  )
    .filter(
      ([_, property]) =>
        property.tablePolicy === StandardPropertyTablePolicy.SHOW
    )
    .map(([key]) => key)

  return (
    <Box className="section">
      <Row className="section-header-row">
        <Typography className="section-title">About</Typography>
        <Button
          className="expand-collapse-button"
          onClick={() => setExpanded(!expanded)}
          size="small"
          endIcon={
            <IconChevronLeft
              size={16}
              className={`expand-arrow ${expanded ? 'expanded' : ''}`}
            />
          }
        >
          {expanded ? 'Fewer' : 'More'}
        </Button>
      </Row>
      <Box className="properties">
        {defaultShownProperties.map((key) => (
          <StandardPropertyRenderer
            key={key}
            propertyId={key}
            property={object.properties.standard[key]}
          />
        ))}
        {expanded && (
          <>
            {secondaryShownProperties.map((key) => (
              <StandardPropertyRenderer
                key={key}
                propertyId={key}
                property={object.properties.standard[key]}
              />
            ))}
          </>
        )}
      </Box>
    </Box>
  )
}

const SidebarCustomPropertyRenderer = ({
  propertyDefinitionId,
  propertyDefinitions,
  value,
  object,
  workspaceId,
  updateProperty,
}: {
  propertyDefinitionId: string
  propertyDefinitions: ObjectPropertyDefinition[]
  value: DayProperty
  object: DayObject
  workspaceId: string
  updateProperty: (property: DayProperty) => void
}) => {
  const propertyDefinition = propertyDefinitions.find(
    (propertyDefinition) => propertyDefinition.id === propertyDefinitionId
  )

  return propertyDefinition ? (
    <Row className="property-row">
      <Box className="property-edit-container">
        <Box className="property-edit-inner">
          <PropertyEdit
            propertyDefinition={propertyDefinition}
            objectId={object.objectId}
            objectType={object.objectType}
            workspaceId={workspaceId}
            value={value}
            justify="start"
            onUpdate={(updatedProperty) => {
              updateProperty(updatedProperty)
            }}
            onClose={() => {}}
            showEditButton={true}
          />
        </Box>
      </Box>
      <Row className="property-row-content">
        <Typography className="property-name">
          {propertyDefinition.name}
        </Typography>
        <Box className="property-value">
          <PropertyDisplay
            propertyDefinition={propertyDefinition}
            objectId={object.objectId}
            objectType={object.objectType as NativeObjectType}
            workspaceId={object.workspaceId}
            value={value}
            justify="flex-start"
          />
        </Box>
      </Row>
    </Row>
  ) : null
}

const SidebarCustomProperties = () => {
  const { object, updateProperty, workspaceId, propertyDefinitions } =
    useSidebar()

  const [expanded, setExpanded] = useState(false)

  return (
    <Box className="section">
      <Row className="section-header-row">
        <Typography className="section-title">Custom Properties</Typography>
        <Button
          className="expand-collapse-button"
          onClick={() => setExpanded(!expanded)}
          size="small"
          endIcon={
            <IconChevronLeft
              size={16}
              className={`expand-arrow ${expanded ? 'expanded' : ''}`}
            />
          }
        >
          {expanded ? 'Fewer' : 'More'}
        </Button>
      </Row>
      <Box className="properties">
        {propertyDefinitions
          .slice(0, expanded ? propertyDefinitions.length : 3)
          .map((propertyDefinition) => {
            const propertyValue =
              object?.properties?.custom?.[propertyDefinition.id]?.value || null
            return (
              propertyDefinition && (
                <SidebarCustomPropertyRenderer
                  key={propertyDefinition.id}
                  propertyDefinitionId={propertyDefinition.id}
                  propertyDefinitions={propertyDefinitions}
                  value={propertyValue}
                  object={object}
                  workspaceId={workspaceId}
                  updateProperty={updateProperty}
                />
              )
            )
          })}
      </Box>
    </Box>
  )
}

const _SidebarRelationships = () => {
  const { object } = useSidebar()
  return [NativeObjectTypes.Organization].includes(
    object?.objectType as NativeObjectType
  ) ? (
    <Box className="section">
      <Relationships object={object} />
    </Box>
  ) : null
  // TODO: Add Person Relationships here
}

const SidebarModeView = () => {
  const { object } = useSidebar()

  return (
    object && (
      <Box className="sidebar-content-mode-view">
        <SidebarHeader />
        <SidebarTabs />
        <Box className="sidebar-content-mode-view-inner">
          {/* <SidebarRelationships /> */}

          <SidebarStandardProperties />
          <SidebarCustomProperties />
        </Box>
      </Box>
    )
  )
}

export default SidebarModeView
